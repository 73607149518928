var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "inherit" } },
    [
      _c("div", {
        staticClass: "body-content-overlay",
        class: {
          show:
            _vm.shallShowUserProfileSidebar ||
            _vm.shallShowActiveChatContactSidebar ||
            _vm.mqShallShowLeftSidebar
        },
        on: {
          click: function($event) {
            _vm.mqShallShowLeftSidebar = _vm.shallShowActiveChatContactSidebar = _vm.shallShowUserProfileSidebar = false
          }
        }
      }),
      _c("section", { staticClass: "chat-app-window" }, [
        !_vm.activeChat.contact
          ? _c("div", { staticClass: "start-chat-area" }, [
              _c(
                "div",
                { staticClass: "mb-1 start-chat-icon" },
                [
                  _c("feather-icon", {
                    attrs: { icon: "MessageSquareIcon", size: "56" }
                  })
                ],
                1
              ),
              _c(
                "h4",
                {
                  staticClass: "sidebar-toggle start-chat-text",
                  on: { click: _vm.startConversation }
                },
                [_vm._v(" Start Conversation ")]
              )
            ])
          : _c(
              "div",
              { staticClass: "active-chat" },
              [
                _c("div", { staticClass: "chat-navbar" }, [
                  _c("header", { staticClass: "chat-header" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "sidebar-toggle d-block d-lg-none mr-1"
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "cursor-pointer",
                              attrs: { icon: "MenuIcon", size: "21" },
                              on: {
                                click: function($event) {
                                  _vm.mqShallShowLeftSidebar = true
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c("b-avatar", {
                          staticClass: "mr-1 cursor-pointer badge-minimal",
                          attrs: {
                            size: "36",
                            src: _vm.activeChat.contact.avatar,
                            badge: "",
                            "badge-variant": _vm.resolveAvatarBadgeVariant(
                              _vm.activeChat.contact.status
                            )
                          },
                          nativeOn: {
                            click: function($event) {
                              _vm.shallShowActiveChatContactSidebar = true
                            }
                          }
                        }),
                        _c("h6", { staticClass: "mb-0" }, [
                          _vm._v(
                            " " + _vm._s(_vm.activeChat.contact.fullName) + " "
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center" },
                      [
                        _c("feather-icon", {
                          staticClass: "cursor-pointer d-sm-block d-none mr-1",
                          attrs: { icon: "PhoneCallIcon", size: "17" }
                        }),
                        _c("feather-icon", {
                          staticClass: "cursor-pointer d-sm-block d-none mr-1",
                          attrs: { icon: "VideoIcon", size: "17" }
                        }),
                        _c("feather-icon", {
                          staticClass: "cursor-pointer d-sm-block d-none mr-50",
                          attrs: { icon: "SearchIcon", size: "17" }
                        }),
                        _c(
                          "div",
                          { staticClass: "dropdown" },
                          [
                            _c(
                              "b-dropdown",
                              {
                                attrs: {
                                  variant: "link",
                                  "no-caret": "",
                                  "toggle-class": "p-0",
                                  right: ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "button-content",
                                    fn: function() {
                                      return [
                                        _c("feather-icon", {
                                          staticClass: "align-middle text-body",
                                          attrs: {
                                            icon: "MoreVerticalIcon",
                                            size: "17"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              },
                              [
                                _c("b-dropdown-item", [
                                  _vm._v(" View Contact ")
                                ]),
                                _c("b-dropdown-item", [
                                  _vm._v(" Mute Notifications ")
                                ]),
                                _c("b-dropdown-item", [
                                  _vm._v(" Block Contact ")
                                ]),
                                _c("b-dropdown-item", [_vm._v(" Clear Chat ")]),
                                _c("b-dropdown-item", [_vm._v(" Report ")])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _c(
                  "vue-perfect-scrollbar",
                  {
                    ref: "refChatLogPS",
                    staticClass: "user-chats scroll-area",
                    attrs: { settings: _vm.perfectScrollbarSettings }
                  },
                  [
                    _c("chat-log", {
                      attrs: {
                        "chat-data": _vm.activeChat,
                        "profile-user-avatar": _vm.profileUserDataMinimal.avatar
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-form",
                  {
                    staticClass: "chat-app-form",
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.sendMessage($event)
                      }
                    }
                  },
                  [
                    _c(
                      "b-input-group",
                      {
                        staticClass: "input-group-merge form-send-message mr-1"
                      },
                      [
                        _c("b-form-input", {
                          attrs: { placeholder: "Enter your message" },
                          model: {
                            value: _vm.chatInputMessage,
                            callback: function($$v) {
                              _vm.chatInputMessage = $$v
                            },
                            expression: "chatInputMessage"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-button",
                      { attrs: { variant: "primary", type: "submit" } },
                      [_vm._v(" Send ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
      ]),
      _c("chat-active-chat-content-details-sidedbar", {
        attrs: {
          "shall-show-active-chat-contact-sidebar":
            _vm.shallShowActiveChatContactSidebar,
          contact: _vm.activeChat.contact || {}
        },
        on: {
          "update:shallShowActiveChatContactSidebar": function($event) {
            _vm.shallShowActiveChatContactSidebar = $event
          },
          "update:shall-show-active-chat-contact-sidebar": function($event) {
            _vm.shallShowActiveChatContactSidebar = $event
          }
        }
      }),
      _c(
        "portal",
        { attrs: { to: "content-renderer-sidebar-left" } },
        [
          _c("chat-left-sidebar", {
            attrs: {
              "chats-contacts": _vm.chatsContacts,
              contacts: _vm.contacts,
              "active-chat-contact-id": _vm.activeChat.contact
                ? _vm.activeChat.contact.id
                : null,
              "shall-show-user-profile-sidebar":
                _vm.shallShowUserProfileSidebar,
              "profile-user-data": _vm.profileUserData,
              "profile-user-minimal-data": _vm.profileUserDataMinimal,
              "mq-shall-show-left-sidebar": _vm.mqShallShowLeftSidebar
            },
            on: {
              "update:shallShowUserProfileSidebar": function($event) {
                _vm.shallShowUserProfileSidebar = $event
              },
              "update:shall-show-user-profile-sidebar": function($event) {
                _vm.shallShowUserProfileSidebar = $event
              },
              "update:mqShallShowLeftSidebar": function($event) {
                _vm.mqShallShowLeftSidebar = $event
              },
              "update:mq-shall-show-left-sidebar": function($event) {
                _vm.mqShallShowLeftSidebar = $event
              },
              "show-user-profile": _vm.showUserProfileSidebar,
              "open-chat": _vm.openChatOfContact
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }