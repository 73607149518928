var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "user-profile-sidebar",
      class: { show: _vm.shallShowActiveChatContactSidebar }
    },
    [
      _vm.contact
        ? _c("header", { staticClass: "user-profile-header" }, [
            _c(
              "span",
              { staticClass: "close-icon" },
              [
                _c("feather-icon", {
                  attrs: { icon: "XIcon" },
                  on: {
                    click: function($event) {
                      return _vm.$emit(
                        "update:shall-show-active-chat-contact-sidebar",
                        false
                      )
                    }
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "header-profile-sidebar" }, [
              _c(
                "div",
                { staticClass: "avatar box-shadow-1 avatar-xl avatar-border" },
                [
                  _c("b-avatar", {
                    attrs: { size: "70", src: _vm.contact.avatar }
                  }),
                  _c("span", {
                    staticClass: "avatar-status-xl",
                    class: "avatar-status-" + _vm.contact.status
                  })
                ],
                1
              ),
              _c("h4", { staticClass: "chat-user-name" }, [
                _vm._v(" " + _vm._s(_vm.contact.fullName) + " ")
              ]),
              _c("span", { staticClass: "user-post text-capitalize" }, [
                _vm._v(_vm._s(_vm.contact.role))
              ])
            ])
          ])
        : _vm._e(),
      _c(
        "vue-perfect-scrollbar",
        {
          staticClass: "user-profile-sidebar-area scroll-area",
          attrs: { settings: _vm.perfectScrollbarSettings }
        },
        [
          _c("h6", { staticClass: "section-label mb-1" }, [_vm._v(" About ")]),
          _c("p", [_vm._v(_vm._s(_vm.contact.about))]),
          _c("div", { staticClass: "personal-info" }, [
            _c("h6", { staticClass: "section-label mb-1 mt-3" }, [
              _vm._v(" Personal Information ")
            ]),
            _c("ul", { staticClass: "list-unstyled" }, [
              _c(
                "li",
                { staticClass: "mb-1" },
                [
                  _c("feather-icon", {
                    staticClass: "mr-75",
                    attrs: { icon: "MailIcon", size: "16" }
                  }),
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v("lucifer@email.com")
                  ])
                ],
                1
              ),
              _c(
                "li",
                { staticClass: "mb-1" },
                [
                  _c("feather-icon", {
                    staticClass: "mr-75",
                    attrs: { icon: "PhoneCallIcon", size: "16" }
                  }),
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v("+1(123) 456 - 7890")
                  ])
                ],
                1
              ),
              _c(
                "li",
                [
                  _c("feather-icon", {
                    staticClass: "mr-75",
                    attrs: { icon: "ClockIcon", size: "16" }
                  }),
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v("Mon - Fri 10AM - 8PM")
                  ])
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "more-options" }, [
            _c("h6", { staticClass: "section-label mb-1 mt-3" }, [
              _vm._v(" Options ")
            ]),
            _c("ul", { staticClass: "list-unstyled" }, [
              _c(
                "li",
                { staticClass: "cursor-pointer mb-1" },
                [
                  _c("feather-icon", {
                    staticClass: "mr-75",
                    attrs: { icon: "TagIcon", size: "16" }
                  }),
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v("Add Tag")
                  ])
                ],
                1
              ),
              _c(
                "li",
                { staticClass: "cursor-pointer mb-1" },
                [
                  _c("feather-icon", {
                    staticClass: "mr-75",
                    attrs: { icon: "StarIcon", size: "16" }
                  }),
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v("Important Contact")
                  ])
                ],
                1
              ),
              _c(
                "li",
                { staticClass: "cursor-pointer mb-1" },
                [
                  _c("feather-icon", {
                    staticClass: "mr-75",
                    attrs: { icon: "ImageIcon", size: "16" }
                  }),
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v("Shared Media")
                  ])
                ],
                1
              ),
              _c(
                "li",
                { staticClass: "cursor-pointer mb-1" },
                [
                  _c("feather-icon", {
                    staticClass: "mr-75",
                    attrs: { icon: "TrashIcon", size: "16" }
                  }),
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v("Delete Contact")
                  ])
                ],
                1
              ),
              _c(
                "li",
                { staticClass: "cursor-pointer" },
                [
                  _c("feather-icon", {
                    staticClass: "mr-75",
                    attrs: { icon: "SlashIcon", size: "16" }
                  }),
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v("Block Contact")
                  ])
                ],
                1
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }